.mobile-table > .ant-table .ant-table-thead {
  display: none;
}
.mobile-table > .ant-table .ant-table-cell {
  border-bottom: none;
  padding: 0;
  padding-bottom: 2rem;
}
.mobile-table > .ant-table .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: inherit;
}
.mobile-table > .ant-table .ant-card-head {
  background-color: #f9f9f9;
}
.mobile-table > .ant-table .section > div:first-child {
  font-weight: 600
}
.mobile-table > .ant-table .section > div:not(:first-child) {
}

