/* standard css form antd for editable table cells */
.replaceCostsTable .editable-cell {
  position: relative;
}

.replaceCostsTable .editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

/* removed hover to always show */
.replaceCostsTable .editable-row .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}


